import Prices from "components/Prices";
import { PRODUCTS } from "data/data";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { LineItem, Order, SEND_TYPE } from "data/types";
import { useGetHistoricalRefundedQuery, useGetOrdersQuery, useRetryPaymentMutation, useUpdateOrderMutation } from "store/api/ordersApi";
import { useCollege, useUser } from "store/utils/hooks";
import { ORDER_STATUS, getOrderStatus } from "utils/get-order-status";
import { useState } from "react";
import { handleScrollToEl } from "containers/PageCheckout/CheckoutPage";
import { _renderLoading } from "components/SectionSliderProductCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import getEuro from "utils/getEuro";
import { Link } from "react-router-dom";
import { metaData } from "utils/get-meta-data";
import { PdfBillDownload } from "./PdfBillDownload";
import { StockWarning } from "containers/ProductDetailPage/GroupedDetailPage";
import PaymentForm from "components/PaymentForm";
import { BILL_STATUS } from "constants/data";
import { CAN_REFUND } from "../../constants/data";
import toast from "react-hot-toast";
import ConfirmDialog from "components/ConfirmDialog";
import { PlusIcon } from "@heroicons/react/24/outline";

export type HistoryRefund = {
  devolution:number
  reason?:string
  approved_at?:string,
  reasonOption:string
  cancelled_at?:string,
  refundImage?:string
  created_at:string
  sendPrice:number
  send_type:'school'|'direction'
  line_items:{
    product:{
      composed_name:string
      name:string
      price:number
    }
    devolution:number
    accepted_devolution:number
  }[]
}

const AccountOrder = () => {
  const user = useUser();
  const { data, isLoading } = useGetOrdersQuery(user?.id);
  const [update,{isLoading:isUpdatingOrder}] = useUpdateOrderMutation()
  const [expandedOrderId, setExpandedOrderId] = useState<number | null>(null);
  const [expandedOrderBillId, setExpandedOrderBillId] = useState<number | null>(
    null
  );
  const [expandedDevolutionOrderId, setExpandedDevolutionOrderId] = useState<number|null>(null)
  const [isConfirmCancel,setIsConfirmCancel] = useState(false)
  const [retry,{data:dataRetry,isSuccess:isSuccessRetry}] = useRetryPaymentMutation()
  const {data:historical,isFetching:isLoadingHistorical} = useGetHistoricalRefundedQuery(expandedDevolutionOrderId,{
    skip:!expandedDevolutionOrderId
  })
  const [isLoadingRetry,setIsLoadingRetry] = useState<{[key:number]:boolean|undefined}>({})
  const navigate = useNavigate();
  const college = useCollege();
  const renderProductItem = (
    product: LineItem,
    index: number,
    order: Order
  ) => {
    const {
      image,
      name,
      amount,
      total,
      price,
      id,
      product_id,
      devolution,
      partial_send,
      attributes,
      no_stock,
    } = product;
    const isFinally =
      order.status === ORDER_STATUS.CANCELLED ||
      order.status === ORDER_STATUS.FULL_COMPLETE ||
      order.status === ORDER_STATUS.COMPLETE ||
      order.status === ORDER_STATUS.DEVOLUTION_REQUEST ||
      order.status === ORDER_STATUS.REFUNDED;
    const isParcial = order.status === ORDER_STATUS.PARTIAL_SEND;
    const isNotInStock = !!(!isFinally && no_stock);
    return (
      <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={image}
            alt={name}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <h3 className="text-base font-medium line-clamp-1">{name}</h3>
              {devolution !== 0 ? (
                <Prices
                  className="mt-0.5 ml-2"
                  price={Number(total)}
                  refund_price={Number(total) - devolution * Number(price)}
                />
              ) : (
                <Prices className="mt-0.5 ml-2" price={Number(total)} />
              )}
            </div>
          </div>
          {isParcial && partial_send !== 0 && (
            <div
              className={`mt-2 ml-auto py-2 text-sm flex items-center border-2 border-orange-500 rounded-lg px-2`}
            >
              <span className={`text-orange-500 !leading-none font-medium`}>
                {partial_send} Enviado{partial_send !== 1 ? "s" : ""}
              </span>
            </div>
          )}
          {attributes && (
            <div className="flex flex-wrap gap-4 text-gray-700">
              {attributes?.map((attr, idx) => {
                return (
                  <div>
                    {attr.name}: {attr.terms?.[0]?.name}
                  </div>
                );
              })}
            </div>
          )}
          {isNotInStock && (
            <div>
              <p className="text-red-400 text-xs">
                En fabricación, el producto se enviará cuando las cantidades
                vuelvan a estar disponibles
              </p>
            </div>
          )}
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="hidden sm:inline-block">Cantidad</span>
              <span className="inline-block sm:hidden">x</span>
              <span className={`ml-2 ${devolution ? "line-through" : ""}`}>
                {amount}
              </span>
              {devolution !== 0 && (
                <span className="ml-2">{amount - devolution}</span>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (order: Order) => {
    const sendPrice = order?.send_price;
    const refundedSendPrice = order?.refunded?.sendPrice * -1
    const devolucion = order?.line_items?.reduce((acc, curr) => {
      acc += curr.devolution * curr.price;
      return acc;
    }, 0);
    const isFinally =
      order.status === ORDER_STATUS.FULL_COMPLETE ||
      order.status === ORDER_STATUS.COMPLETE ||
      order.status === ORDER_STATUS.DEVOLUTION_REQUEST ||
      order.status === ORDER_STATUS.REFUNDED;
    const isNotInStock =
      !isFinally && order?.line_items?.some((it) => it.no_stock);
    return (
      <div
        key={`collapse-order-${order.id}`}
        id={`collapse-order-${order.id}`}
        className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0"
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div className="flex gap-4">
            <div>
              <p className="text-lg font-semibold">
                #{order.id}
                <span className="mx-2">·</span>
                <span className={`${devolucion !== 0 ? "line-through" : ""}`}>
                  {getEuro(order.total_price)}
                </span>
                {devolucion !== 0 && (
                  <>
                    <span className="mx-2">·</span>
                    <span className="mx-2">
                      {getEuro(order.total_price + sendPrice - devolucion + refundedSendPrice)}
                    </span>
                  </>
                )}
              </p>
              {devolucion !== 0 && (
                <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                  <span>
                    Reembolso{"(" + order.refunded_bill_number + ")"}:{" "}
                    {getEuro(devolucion - refundedSendPrice)}
                  </span>
                  {/* <span className="mx-2">·</span> */}
                  <br />
                </p>
              )}
              <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                {order.send_type === SEND_TYPE.COLLEGE
                  ? "Envío Gratuito al Colegio"
                  : order.send_type === SEND_TYPE.FREE
                  ? "Gastos de Envío Gratuitos"
                  : "Gastos de Envío"}
                : {getEuro(sendPrice)}
              </p>
              <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                <span>
                  {new Date(order.created_at).toLocaleDateString("es-es", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
                <span className="mx-2">·</span>
                <span style={{ color: getOrderStatus(order.status).color }}>
                  {getOrderStatus(order.status).label}
                </span>
              </p>
              {isNotInStock && expandedOrderId !== order.id && (
                <div style={{ maxWidth: 400 }}>
                  <StockWarning short />
                </div>
              )}
            </div>
          </div>
          <div className="mt-3 sm:mt-0 space-x-4 space-y-4">
            {BILL_STATUS.includes(order.status) && (
              <ButtonSecondary
                onClick={
                  expandedOrderBillId !== order.id
                    ? () => {
                        setExpandedOrderId(null);
                        setExpandedOrderBillId(order.id);
                        setExpandedDevolutionOrderId(null)
                        handleScrollToEl(`collapse-order-${order.id}`);
                      }
                    : () => setExpandedOrderBillId(null)
                }
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
              >
                {expandedOrderBillId !== order.id
                  ? "Ver Facturas"
                  : "Esconder Facturas"}
              </ButtonSecondary>
            )}
            {CAN_REFUND.includes(order.status) && 
            // order.can_refund && 
            (
              <ButtonSecondary
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
                // onClick={() => navigate(`/refunds?order=${order.id}`)}
                onClick={()=> {
                  if(expandedDevolutionOrderId === order.id){
                    setExpandedDevolutionOrderId(null)
                  }else{
                    setExpandedDevolutionOrderId(order.id)
                    setExpandedOrderBillId(null);
                    setExpandedOrderId(null);
                    handleScrollToEl(`collapse-order-${order.id}`);
                  }
                }}
              >
                Devolución
              </ButtonSecondary>
            )}
            {order.status === ORDER_STATUS.PENDING && (
              <ButtonSecondary
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
                loading={isUpdatingOrder}
                onClick={()=>setIsConfirmCancel(order.id)}
              >
                Cancelar
              </ButtonSecondary>
            )}
            {order.status === ORDER_STATUS.PENDING && (
              <ButtonSecondary
                sizeClass="py-2.5 px-4 sm:px-6"
                fontSize="text-sm font-medium"
                loading={isLoadingRetry[order.id]}
                onClick={async () => {
                  setIsLoadingRetry(prev=>({
                    ...prev,
                    [order.id]:true
                  }))
                  retry(order.id).finally(()=>{
                    setIsLoadingRetry((prev)=>({
                      ...prev,
                      [order.id]:undefined
                    }))
                  })
                }}
              >
                Continuar Pago
              </ButtonSecondary>
            )}
            {order.status === ORDER_STATUS.PENDING &&
              isSuccessRetry && (
                <PaymentForm paymentParams={dataRetry.data} />
              )}
            <ButtonPrimary
              onClick={
                expandedOrderId !== order.id
                  ? () => {
                      setExpandedOrderId(order.id);
                      setExpandedOrderBillId(null);
                      setExpandedDevolutionOrderId(null)
                      handleScrollToEl(`collapse-order-${order.id}`);
                    }
                  : () => setExpandedOrderId(null)
              }
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-sm font-medium"
            >
              {expandedOrderId !== order.id ? "Ver Pedido" : "Ocultar Pedido"}
            </ButtonPrimary>
          </div>
        </div>
        {expandedOrderId === order.id && (
          <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
            {order.line_items.map((it, idx) =>
              renderProductItem(it, idx, order)
            )}
          </div>
        )}
        {expandedDevolutionOrderId === order.id && (
          <div className="p-2 sm:p-8 space-y-4">
            {
              isLoadingHistorical && <div className="w-full flex justify-center">{_renderLoading()}</div>
            }
            {
              !isLoadingHistorical && historical && <div className="space-y-4"> 
                {historical?.data?.map(({created_at,line_items,sendPrice, send_type, approved_at, cancelled_at}:HistoryRefund)=>{
                  const subtotal = (line_items?.reduce((acc:number,curr:any)=>{
                    acc += curr.devolution * curr.product.price
                    return acc
                  },0))
                  const total = subtotal + sendPrice
                  const isNotPending = (cancelled_at || approved_at)
                  return <div className="border-b pb-2">
                    <div className="flex justify-between">
                      <h6 className="font-semibold">{new Date(created_at).toLocaleString('es',{
                        day:'2-digit',
                        month:'long',
                        year:'numeric',
                        hour:'2-digit',
                        minute:'2-digit',
                        hour12:true
                      })}</h6>
                      {
                        !!approved_at
                        ?<span className="text-green-500 font-semibold">Aprobada</span>
                        :!!cancelled_at
                        ?<span className="text-red-500 font-semibold">Cancelada</span>
                        :<span className="text-orange-500 font-semibold">Pendiente</span>
                      }
                    </div>
                    <div className="flex max-md:flex-col w-full gap-12">
                      <ul className="space-y-2 py-4 flex-1">
                        {line_items?.map((product,idx)=>(
                          <li className="max-sm:flex-wrap flex gap-4 max-sm:border-b max-sm:pb-2">
                            <span className="max-sm:hidden">{idx+1}.</span>
                            <span>{product.product?.composed_name}</span>
                            {product.accepted_devolution!==null && isNotPending && product.accepted_devolution!==undefined && product.accepted_devolution !== product.devolution && <span className="font-medium line-through">x{product.devolution}</span>}
                            {product.accepted_devolution!==null && isNotPending && product.accepted_devolution!==undefined && product.accepted_devolution !== product.devolution && <span className="font-medium line-through">{getEuro((product.devolution) * product.product.price)}</span>}
                            <span className="font-medium">x{isNotPending?(product.accepted_devolution ?? product.devolution):product.devolution}</span>
                            <span className="font-medium">{getEuro((isNotPending?(product.accepted_devolution ?? product.devolution):product.devolution) * product.product.price)}</span>
                        </li>))}
                      </ul>
                      <div className="text-end space-y-2 py-4">
                        <p>Envío{send_type==='direction'?' a domicilio':send_type==='school'?' al colegio':''}: {sendPrice === 0 ? getEuro(sendPrice) : getEuro(-1*sendPrice) }</p>
                        <p>Subtotal: {getEuro(subtotal)}</p>
                        <p>Total: {getEuro(total)}</p>
                      </div>
                    </div>
                  </div>
                })}
              </div>
            }
            <div className="flex w-full gap-4 justify-center">
              <ButtonSecondary onClick={() => navigate(`/refunds?order=${order.id}`)}>
                <PlusIcon width={24} className="mr-2"/>
                Crear nueva devolución
              </ButtonSecondary>
            </div>
          </div>
        )}
        {expandedOrderBillId === order.id && (
          <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
            <PdfBillDownload
              order={order}
              className="py-4 last:pb-0 first:pt-0"
            >
              <p className="font-medium ml-4 text-lg">Descargar Factura</p>
            </PdfBillDownload>
            {order.status === ORDER_STATUS.REFUNDED && (
              <PdfBillDownload
                isRefund
                order={order}
                className="py-4 last:pb-0 first:pt-0"
              >
                <p className="font-medium ml-4 text-lg">
                  Descargar Factura Rectificativa
                </p>
              </PdfBillDownload>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            Historial de Pedidos
          </h2>
          {isLoading && _renderLoading()}
          {data?.map((it: Order) => {
            return renderOrder(it);
          })}
          {!isLoading && (!data || data.length === 0) && (
            <h5>Todavía no ha realizado ningún pedido.</h5>
          )}
        </div>
      </CommonLayout>
      <ConfirmDialog 
        onConfirm={async () => {
          const res = await update({id:isConfirmCancel,status:ORDER_STATUS.CANCELLED})
          if(res.error){
            toast.error("Error cancelando su pedido")
          }else{
            toast.success("Pedido cancelado")
          }
        }} 
        title={`Cancelar pedido #${isConfirmCancel}`} 
        desc={'¿Seguro desea cancelar este pedido?'} 
        open={isConfirmCancel} 
        hide={()=>setIsConfirmCancel(false)}
      />
    </div>
  );
};

export default AccountOrder;
